<template>
  <div class="container">
    <el-row :gutter="20">
      <el-col :span="8">
        <el-card shadow="hover" class="mgb20" style="height: 195px">
          <div class="user-info">
            <img :src="image" class="user-avator" alt />
            <div class="user-info-cont">
              <div class="user-info-name">{{ name }}</div>
            </div>
          </div>
        </el-card>
        <el-card shadow="hover" style="height: 150px">
          <template #header>
            <div class="clearfix">
              <span>数据统计：</span>
            </div>
          </template>
          <div class="data_tongji">
            <p>学校数量:{{ schoolnumber }}</p>
            <br />
            <p>读者数量:{{ readernumber }}</p>
            <br />
            <p>校均藏书:{{ avgschoolbooks }}</p>
            <br />
            <p>生均藏书:{{ avgstudentbooks }}</p>
            <br/>
            <br />
          </div>
          <div class="data_tongji">
            <p>当年新增馆藏:{{ newbooks }}</p>
            <br />
            <p>累计丢失:{{ lostbooks }}</p>
            <br />
            <p>馆藏总金额:{{ totalprice }}</p>
            <br />
            <br />
          </div>
        </el-card>
      </el-col>
      <el-col :span="16">
        <el-row :gutter="10" class="mgb20">
          <el-col :span="8">
            <el-card shadow="hover" :body-style="{ padding: '0px' }">
              <div class="grid-content grid-con-1">
                <i class="el-icon-notebook-1 grid-con-icon"></i>
                <div class="grid-cont-right">
                  <div class="grid-num">{{ totalbookscount }}</div>
                  <div>馆藏总量</div>
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card shadow="hover" :body-style="{ padding: '0px' }">
              <div class="grid-content grid-con-2">
                <i class="el-icon-view grid-con-icon"></i>
                <div class="grid-cont-right">
                  <div class="grid-num">{{ totalborrow }}</div>
                  <div>累计借阅</div>
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card shadow="hover" :body-style="{ padding: '0px' }">
              <div class="grid-content grid-con-3">
                <i class="el-icon-finished grid-con-icon"></i>
                <div class="grid-cont-right">
                  <div class="grid-num">{{ totalreturn }}</div>
                  <div>累计归还</div>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <el-card shadow="hover">
          <schart
            ref="line"
            class="schart"
            canvasId="trends"
            :options="options"
            v-loading="trendload"
          ></schart>
        </el-card>
      </el-col>
    </el-row>

     <el-row :gutter="10">
      <el-col :span="12">
        <el-card shadow="hover">
          <schart
            ref="bar"
            class="schart"
            canvasId="class"
            :options="options1"
          ></schart>
        </el-card>
      </el-col>
<!--    <el-row :gutter="10">-->
<!--      <el-col :span="12">-->
<!--        <el-card shadow="hover">-->
<!--          <div id="rankClass" style="width: 100%; height: 300px"></div>-->
<!--        </el-card>-->
<!--      </el-col>-->
      <el-col :span="12">
        <el-card shadow="hover">
          <schart
            ref="bar"
            class="schart"
            canvasId="student"
            :options="options2"
          ></schart>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Schart from "vue-schart";
// eslint-disable-next-line no-unused-vars
import * as echarts from "echarts";
export default {
  name: "dashboard",
  data() {
    return {
      image: 1,
      schoolnumber: 0,
      readernumber: 0,
      avgschoolbooks: 0,
      avgstudentbooks: 0,
      newbooks: 0,
      lostbooks: 0,
      totalprice: 0,
      totalbookscount: 0,
      totalborrow: 0,
      totalreturn: 0,
      trendload: true,
      name: localStorage.getItem("username"),
      // options1: {
      //   type: "bar",
      //   title: {
      //     text: "小组积分排名",
      //   },
      //   xRorate: 0,
      //   labels: ["没有小组有积分"],
      //   datasets: [
      //     {
      //       label: "小组积分",
      //       data: [0],
      //       fillColor: "rgb(234,114,148)",
      //     },
      //   ],
      // },
      options1: {
        type: "bar",
        title: {
          text: "校借阅排行",
        },
        xRorate: 10,
        labels: ["暂无校借阅"],
        datasets: [
          {
            label: "校借阅",
            data: [0],
            rotate : 60,
            fillColor: "rgb(232,149,119)",
          },
        ],
      },
      options: {
        type: "line",
        title: {
          text: "借还趋势",
        },
        labels: ["没人借还"],
        datasets: [
          {
            label: "借阅",
            data: [0],
            fillColor: "rgb(88,189,157)",
          },
          {
            label: "归还",
            data: [0],
            fillColor: "rgb(107,160,236)",
          },
        ],
      },
      options2: {
        type: "bar",
        title: {
          text: "图书借阅排行",
        },
        xRorate: 10,
        labels: ["暂无图书借阅"],
        datasets: [
          {
            label: "图书借阅",
            data: [0],
            fillColor: "rgb(232,149,119)",
          },
        ],
      }
    };
  },
  components: {
    Schart,
  },
  mounted() {
    //解决echarts图表第一次加载显示空白问题 暂时没找到好方案 ?
    // if (location.href.indexOf("#reloaded") === -1) {
    //   location.href = location.href + "#reloaded";
    //   location.reload();
    // }
    // var rankCharst = echarts.init(document.getElementById("rankClass"));
    // rankCharst.setOption(this.options1);
  },
  // watch: {
  //   options1: {
  //     handler(newVal, oldVal) {
  //       var rankCharst = echarts.init(document.getElementById("rankClass"));
  //       rankCharst.setOption(newVal);
  //       console.log(oldVal)
  //     },
  //     deep: true, //对象内部属性的监听，关键。
  //   },
  // },
  computed: {},
  created() {
    this.img();
    this.monthborrowed();
    this.monthreturned();
    this.todayborrowed();
    this.todayreturned();
    this.totalbooks();
    this.totalborrowed();
    this.totalreturned();
    this.classranking();
    this.studentranking();
    this.dotrend();
    //获取首页跳转路径
    var path = localStorage.getItem("active");
    if (path) {
      this.$router.push(path);
      localStorage.removeItem("active");
    }
  },
  methods: {
    //获取封面
    img() {
      // this.$axios({
      //   url: this.localpath + 'organizations/images',
      //   method: 'get',
      //   //发送格式为json
      //   headers:
      //       {
      //         'Content-Type': 'application/json',
      //         'dataType': 'json',
      //         'Authorization': localStorage.getItem("token")
      //       }
      // }).then((res) => {
      //   this.image = res.data.msg[0]
      this.image = localStorage.getItem("schoolimg");
      // })
    },
    //学校数量
    monthborrowed() {
      this.$axios({
        url: this.localpath + "area/getorgcount",
        method: "get",
        //发送格式为json
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        this.schoolnumber = res.data.msg;
        if(this.totalbookscount !== 0&&this.readernumber!==0){
          this.avgschoolbooks = this.savetwopoint(this.totalbookscount/this.schoolnumber)
        }
      });
    },
    //读者数量
    monthreturned() {
      this.$axios({
        url: this.localpath + "area/getreadercount",
        method: "get",
        //发送格式为json
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        this.readernumber = res.data.msg;
        if(this.totalbookscount !== 0&&this.readernumber!==0){
          this.avgstudentbooks = this.savetwopoint(this.totalbookscount/this.readernumber)
        }
      });
    },
    //当年新增馆藏
    todayborrowed() {
      this.$axios({
        url: this.localpath + "area/getnewbookcountofyear",
        method: "get",
        //发送格式为json
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        this.newbooks = res.data.msg;
      });
    },
    //累计损毁图书
    todayreturned() {
      this.$axios({
        url: this.localpath + "area/getareamissingandbrokenbook",
        method: "get",
        //发送格式为json
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        this.lostbooks = res.data.msg.totalLost;
      });
    },
    //馆藏总量
    totalbooks() {
      this.$axios({
        url: this.localpath + "organizations/totalareabookscount",
        method: "get",
        //发送格式为json
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        this.totalbookscount = res.data.msg.Count
        this.totalprice = this.savetwopoint(res.data.msg.Price)
        if(this.schoolnumber !== 0 &&this.totalbookscount!==0){
          this.avgschoolbooks = this.savetwopoint(this.totalbookscount/this.schoolnumber)
        }
        if(this.readernumber !== 0&&this.totalbookscount!==0){
          this.avgstudentbooks = this.savetwopoint(this.totalbookscount/this.readernumber)
        }
      });
    },
    //借阅总量
    totalborrowed() {
      this.$axios({
        url: this.localpath + "organizations/totalareaborrowedcount",
        method: "get",
        //发送格式为json
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        this.totalborrow = res.data.msg;
      });
    },
    //归还总量
    totalreturned() {
      this.$axios({
        url: this.localpath + "organizations/totalareareturncount",
        method: "get",
        //发送格式为json
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        this.totalreturn = res.data.msg;
      });
    },
    //校借阅排行
    classranking() {
      this.$axios({
        url: this.localpath + "organizations/toporganizations",
        method: "get",
        //发送格式为json
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        var b = 0;
        if (res.data.msg.length > 9) {
          b = 9;
        } else {
          b = res.data.msg.length;
        }
        var studentrankingdata = [];
        var studentrankingname = [];
        for (var i = 0; i < b; i++) {
          studentrankingdata[i] = res.data.msg[i].TotalBorrow;
          studentrankingname[i] = res.data.msg[i].StrName;
        }
        this.options1.labels = studentrankingname;
        this.options1.datasets[0].data = studentrankingdata;
      // this.options1.labels = classrankingname;
      // this.options1.datasets[0].data = classrankingdata;
    })
    },

    //字符串换行
    tnString(str) {
      var newStr = "";
      for (var i = 0; i < str.length; i++) {
        if (i != 0 && i % 5 == 0) {
          newStr += "\n";
        }
        newStr += str[i];
      }
      return newStr;
    },
    savetwopoint(value) {
      return value.toFixed(2)
    },
    //图书借阅排行
    studentranking() {
      this.$axios({
        url: this.localpath + "organizations/topareabooks",
        method: "get",
        //发送格式为json
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        var b = 0;
        if (res.data.msg.length > 9) {
          b = 9;
        } else {
          b = res.data.msg.length;
        }
        var studentrankingdata = [];
        var studentrankingname = [];
        var title;
        for (var i = 0; i < b; i++) {
          studentrankingdata[i] = res.data.msg[i].TotalCount;
          title = res.data.msg[i].StrPositiveTitle
          // if(title.length>10){
          //   studentrankingname[i] = title.substring(0,11)+'...'
          // }else{
            studentrankingname[i] = title;
          // }
        }
        this.options2.labels = studentrankingname;
        this.options2.datasets[0].data = studentrankingdata;
      });
    },
    //借还趋势
    trend() {
      //归还
      this.$axios({
        //发送格式为json
        headers: {
          "Content-Type": "application/json",
          dataType: "json",

          Authorization: localStorage.getItem("token"),
        },
        url: this.localpath + "organizations/areareturntrends?count=7",
        method: "get",
      }).then((res) => {
        var returns = []
        var maxbiao = res.data.msg.length<15?res.data.msg.length-1:15
        for (var i = 0; i <= maxbiao; i++) {
          returns[maxbiao-i] = res.data.msg[i].number;
        }
        this.options.datasets[1].data = returns;
      });
      //借阅
      this.$axios({
        url: this.localpath + "organizations/areaborrowtrends?count=7",
        method: "get",
        //发送格式为json
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        var days = [];
        var borrows = [];
        var date;
        var maxbiao = res.data.msg.length<15?res.data.msg.length-1:15
        for (var i = 0; i <= maxbiao; i++) {
          date = res.data.msg[i].date.substring(5,10)
          days[maxbiao-i] = date;
          borrows[maxbiao-i] = res.data.msg[i].number;
        }
        this.options.labels = days;
        this.options.datasets[0].data = borrows;
      });
    },
    dotrend() {
      this.trend();
      this.trendload = false;
    },
  },
};
</script>

<style scoped>
.el-row {
  margin-bottom: 20px;
}

.grid-content {
  display: flex;
  align-items: center;
  height: 100px;
}

.grid-cont-right {
  flex: 1;
  text-align: center;
  font-size: 14px;
  color: #999;
}

.grid-num {
  font-size: 30px;
  font-weight: bold;
}

.grid-con-icon {
  font-size: 50px;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  color: #fff;
}

.grid-con-1 .grid-con-icon {
  background: rgb(45, 140, 240);
}

.grid-con-1 .grid-num {
  color: rgb(45, 140, 240);
}

.grid-con-2 .grid-con-icon {
  background: rgb(100, 213, 114);
}

.grid-con-2 .grid-num {
  color: rgb(45, 140, 240);
}

.grid-con-3 .grid-con-icon {
  background: rgb(242, 94, 67);
}

.grid-con-3 .grid-num {
  color: rgb(242, 94, 67);
}

.user-info {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 2px solid #ccc;
  margin-bottom: 20px;
}

.user-avator {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.user-info-cont {
  padding-left: 50px;
  flex: 1;
  font-size: 14px;
  color: #999;
}

.user-info-cont div:first-child {
  font-size: 30px;
  color: #222;
}

.user-info-list {
  font-size: 14px;
  color: #999;
  line-height: 25px;
}

.user-info-list span {
  margin-left: 70px;
}

.mgb20 {
  margin-bottom: 20px;
}

.todo-item {
  font-size: 14px;
}

.todo-item-del {
  text-decoration: line-through;
  color: #999;
}

.schart {
  width: 100%;
  height: 300px;
}

.data_tongji {
  width: 50%;
  float: left;
}
</style>
